import { FC } from 'react';
import { useSelector } from 'react-redux';

import { subscriptionPlanState, subscriptionPlanLinkState } from '../../../redux/slices/loginSlice';
import { useTranslation } from 'react-i18next';

export const SubscriptionHeaderStatus: FC = () => {
    const { t } = useTranslation();

    const subscriptionPlan = useSelector(subscriptionPlanState);
    const subscriptionPlanLink = useSelector(subscriptionPlanLinkState);

    if (!subscriptionPlan) {
        return (
            <div className="flex items-center rounded-xl border border-[#f0f0f0] bg-neutral-50 py-1 pl-1.5 pr-1 md:pl-3">
                <p className="text-center text-xs font-bold text-[#668091] md:text-sm">{t('SUBSCRIPTION_1')}</p>
                <button
                    onClick={() => window.open(`${subscriptionPlanLink}`, '_blank')}
                    type="button"
                    className="ml-2 flex h-7 items-center rounded-lg bg-[#6334e2] py-1 pl-1.5 pr-1 text-xs font-semibold text-white md:ml-5 md:pl-3 md:text-base"
                >
                    {t('SUBSCRIPTION_2')} <span className="ml-2 rounded bg-white py-0.5 pl-1 pr-1.5 text-xs font-bold italic text-[#6334e2]">PRO</span>
                </button>
            </div>
        );
    } else {
        return (
            <div className="rounded-xl bg-[#6d3eec]/10 px-3.5 py-2 text-sm font-bold text-[#6d3eec]">
                {t('SUBSCRIPTION_3')} <span className="rounded bg-[#6d3eec] py-1 pl-1 pr-1.5 text-xs font-bold italic text-white">PRO</span>{' '}
                {t('SUBSCRIPTION_4')}
            </div>
        );
    }
};
