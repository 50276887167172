import { FC } from 'react';

import { ChatFilterByDate } from './ChatFilterByDate';
import { ChatSearch } from './ChatSearch';

type Props = {
    bookingId: string;
    tabBar: 'chat' | 'whatsapp';
};

export const MobileFilterBlock: FC<Props> = ({ bookingId, tabBar }) => {
    return (
        <div className={`flex md:hidden ${!bookingId ? '' : 'hidden'}`}>
            <div className={`${tabBar === 'chat' ? 'w-1/2' : 'w-full'} `}>
                <ChatSearch tabBar={tabBar} />
            </div>

            {tabBar === 'chat' && (
                <div className="fixPosition relative w-1/2">
                    <ChatFilterByDate />
                </div>
            )}
        </div>
    );
};
