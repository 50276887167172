import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { setSubscriptionNotification, showSubscriptionNotificationState, subscriptionPlanLinkState } from '../../../redux/slices/loginSlice';
import { AppDispatch } from '../../../redux/store';
import NotificationPic from '../../../source/images/notificationPic.png';

export const SubscriptionNotification: FC = () => {
    const { t } = useTranslation();

    const dispatch = useDispatch<AppDispatch>();
    const subscriptionNotification = useSelector(showSubscriptionNotificationState);
    const subscriptionPlanLink = useSelector(subscriptionPlanLinkState);

    if (!subscriptionNotification) return null;

    return (
        <div
            className={`absolute bottom-0 z-40 flex w-full flex-col justify-between rounded-t-3xl bg-[#6d3eec] p-3 md:flex-row md:items-center md:p-5 ${
                subscriptionNotification ? 'animate-slide-up' : 'animate-slide-down'
            }`}
        >
            <div className="flex">
                <img src={NotificationPic} alt="icon" width={54} height={54} className="self-center" />
                <div className="ml-2 self-center">
                    <p className="text-base font-semibold text-white md:text-2xl">{t('SUBSCRIPTION_5')}</p>
                    <p className="mt-0.5 text-xs font-semibold text-white/60 md:text-lg">{t('SUBSCRIPTION_6')}</p>
                </div>
            </div>
            <div className="items-centers mt-4 flex w-full flex-col justify-center md:mt-0 md:w-auto md:flex-row">
                <button
                    onClick={() => window.open(`${subscriptionPlanLink}`, '_blank')}
                    type="button"
                    className="w-full self-baseline rounded-[14px] bg-white/90 px-5 py-3 text-sm font-semibold text-[#6d3eec] hover:bg-white md:px-8 md:py-[14px] md:text-lg"
                >
                    {t('SUBSCRIPTION_2')}
                </button>
                <button
                    onClick={() => dispatch(setSubscriptionNotification(false))}
                    type="button"
                    className="mt-2 w-full self-baseline rounded-[14px] bg-white/25 px-3 py-2 text-sm font-semibold text-white hover:bg-white/50 md:ml-2 md:mt-0 md:px-8 md:py-[14px] md:text-lg"
                >
                    {t('CANCEL')}
                </button>
            </div>
        </div>
    );
};
