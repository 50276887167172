import { Dispatch, FC, SetStateAction, useEffect, memo } from 'react';
import { useSelector } from 'react-redux';

import { selectUnreadMessagesByHotelId } from '../../redux/slices/chatSlice';
import { restrictionsState, setSubscriptionNotification } from '../../redux/slices/loginSlice';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux/store';

type Props = { hotelId: string; tab: string; setTab: Dispatch<SetStateAction<'chat' | 'whatsapp'>> };

const TabBarComponent: FC<Props> = ({ hotelId, tab, setTab }) => {
    const { t } = useTranslation();

    const dispatch = useDispatch<AppDispatch>();
    const unreadWhatsAppCount = useSelector(state => selectUnreadMessagesByHotelId(state, hotelId));
    const restriction: string[] = useSelector(restrictionsState);

    useEffect(() => {
        setTab('chat');
    }, [hotelId]);

    const handlerClick = () => {
        if (restriction.includes('whatsapp')) {
            dispatch(setSubscriptionNotification(true));
            return;
        }
        setTab('whatsapp');
    };

    return (
        <div className="border-b p-3">
            <div className="flex h-11 items-center justify-between rounded-[100px] bg-gray-200 p-0.5">
                <button
                    onClick={() => setTab('chat')}
                    type="button"
                    className={`flex w-1/2 items-center justify-center rounded-[100px] ${
                        tab === 'chat' ? 'h-10 bg-white font-semibold' : 'text-gray-500 hover:text-blue-500'
                    }`}
                >
                    {t('CHAT')}
                </button>
                <button
                    onClick={() => handlerClick()}
                    type="button"
                    className={`relative flex w-1/2 items-center justify-center rounded-[100px] ${
                        tab === 'whatsapp' ? 'h-10 bg-white font-semibold' : 'text-gray-500 hover:text-blue-500'
                    }`}
                >
                    <span>WhatsApp</span>
                    {unreadWhatsAppCount && unreadWhatsAppCount.whatsapp_count ? <div className="ml-2 h-2 w-2 rounded-full bg-red-500"></div> : ''}
                </button>
            </div>
        </div>
    );
};

export const TabBar = memo(TabBarComponent);
