import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { tabs as tabs_state } from '../../../../redux/slices/hotelSlice';
import { getUnreadMessagesCount } from '../../../../redux/slices/chatSlice';
import { SidebarMenuHotel } from './SidebarMenuHotel';
import { SidebarMenuItem } from './SidebarMenuItem';
import { restrictionsState } from '../../../../redux/slices/loginSlice';

export const SidebarMenu = () => {
    const dispatch = useDispatch();

    const { t } = useTranslation();

    const tabs = useSelector(tabs_state);
    const restrictions = useSelector(restrictionsState);

    useEffect(() => {
        dispatch(getUnreadMessagesCount());

        const interval = setInterval(async () => {
            await dispatch(getUnreadMessagesCount());
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="space-y-8">
            <ul className="mt-3">
                {!!tabs.length && tabs.map(hotel => <SidebarMenuHotel hotel={hotel} key={`hotel${hotel.id}`} />)}

                {!restrictions.includes('tableUsers') && <SidebarMenuItem title={t('TABLE_TABLE_USERS')} link="/loyalty-table/" type="bold" />}
            </ul>
        </div>
    );
};
