import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getWhatsAppTemplates, set_chat_filter, chat_filter as chat_filter_state } from '../../../redux/slices/chatSlice';

export const ChatSearch = ({ tabBar }) => {
    const { hotel_id } = useParams();
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const chatFilter = useSelector(chat_filter_state);

    const [searchLine, setSearchLine] = useState(chatFilter ? chatFilter : '');

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            if (tabBar === 'chat') {
                dispatch(set_chat_filter(searchLine));
            } else {
                const data = {
                    hotel_id,
                    search: searchLine,
                };
                dispatch(set_chat_filter(searchLine));
                dispatch(getWhatsAppTemplates({ data }));
            }
        }, 1000);
        return () => clearTimeout(timeOutId);
    }, [searchLine, dispatch]);

    useEffect(() => {
        if (tabBar === 'chat') {
            setSearchLine(chatFilter ? chatFilter : '');
        }
    }, [tabBar]);

    return (
        <input
            className="w-full shrink-0 border border-gray-100 px-3 py-2 text-sm md:w-auto"
            value={searchLine ? searchLine : ''}
            onChange={e => setSearchLine(e.target.value)}
            placeholder={t('CHAT_SEARCH')}
        />
    );
};
