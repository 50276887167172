import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { tabs as tabs_state } from '../redux/slices/hotelSlice';
import { Header } from '../components/chat/Header';
import { List } from '../components/chat/List';
import { Detail } from '../components/chat/Detail';
import { MobileFilterBlock } from '../components/chat/filters/MobileFilterBlock';

export const ChatPage = () => {
    const [tab, setTab] = useState(null);
    const [bookingId, setBookingId] = useState('');
    const [tabBar, setTabBar] = useState('chat');

    const tabs = useSelector(tabs_state);
    const { hotel_id } = useParams();

    useEffect(() => {
        if (tabs.length && hotel_id) {
            const hotel = tabs.find(hotel => hotel.id === hotel_id);
            if (hotel) {
                setTab({
                    ...hotel.subitems.find(tab => tab.id_tab === '2'),
                    hotel_name: hotel.Name,
                });
            }
            setBookingId('');
        }
    }, [tabs, hotel_id]);

    return (
        <>
            <Header tab={tab} tabBar={tabBar} />

            <MobileFilterBlock bookingId={bookingId} tabBar={tabBar} />

            <div className="flex h-[calc(100vh-4rem)] w-full flex-col overflow-hidden bg-white md:h-[calc(100vh-9rem)] md:flex-row">
                <List setBookingId={setBookingId} bookingId={bookingId} tab={tab} tabBar={tabBar} setTabBar={setTabBar} />
                <Detail setBookingId={setBookingId} bookingId={bookingId} tab={tab} tabBar={tabBar} />
            </div>
        </>
    );
};
