import { getIcon } from '../../../../api/functions';
import { useDispatch, useSelector } from 'react-redux';
import { restrictionsState, setSubscriptionNotification } from '../../../../redux/slices/loginSlice';

export const SidebarMenuItemLinkBlank = ({ title, icon, link }) => {
    const dispatch = useDispatch();

    const restrictions = useSelector(restrictionsState);

    const handlerClick = e => {
        if (restrictions.includes(icon)) {
            e.preventDefault();
            dispatch(setSubscriptionNotification(true));
        }
    };

    return (
        <li>
            <a href={link} onClick={handlerClick} target="_blank" className="block py-2 pl-6 pr-3 transition-colors hover:text-blue-500">
                <div className="flex truncate text-base">
                    <span className={`shrink-0 ${icon ? 'mr-2' : ''}`}>{getIcon(icon)}</span>
                    <span className={`truncate`}>{title}</span>
                </div>
            </a>
        </li>
    );
};
